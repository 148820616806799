<template>
    <div>

      <control-add-new-sidebar
        :control-type-options="controlTypeOptions"
        :is-control-add-new-sidebar-active.sync = "isControlAddNewSidebarActive"
        :controls.sync="controls"/>

      <control-edit-sidebar
        :control-type-options="controlTypeOptions"
        :is-control-edit-sidebar-active.sync = "isControlEditSidebarActive"
        :editing-control="editingControl"
        @save-edit-control="saveEditControl($event)"/>

      <workflow-add-new-sidebar
        :is-workflow-add-new-sidebar-active.sync = "isWorkflowAddNewSidebarActive"
        :user-options="userOptions"
        :workflows.sync="workflows"/>

      <workflow-edit-sidebar
        :is-workflow-edit-sidebar-active.sync = "isWorkflowEditSidebarActive"
        :user-options="userOptions"
        :editing-workflow="editingWorkflow"
        @save-edit-workflow="saveEditWorkflow($event)"/>

      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="保存"
        back-button-text="上一项"
        next-button-text="下一项"
        class="mb-3"
        @on-complete="saveNewApproval"
      >
      <tab-content
        title="填写基本信息"
        :before-change="validateBasicInfoStep"
      >
        <validation-observer
          ref="basicInfoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                填写基本信息
              </h5>
              <small class="text-muted">
                请填写审批流的基本信息
              </small>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="审批流名称"
                label-for="审批流名称"
              >
                <validation-provider
                  #default="{ errors }"
                  name="审批流名称"
                  rules="required"
                >
                  <b-form-input
                    id="approvalName"
                    v-model="approvalName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="审批流描述"
                label-for="审批流描述"
              >
                <validation-provider
                  #default="{ errors }"
                  name="审批流描述"
                  rules="required"
                >
                  <b-form-input
                    id="approvalDescription"
                    v-model="approvalDescription"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="选择管理员"
        :before-change="validateManagerStep"
      >
        <validation-observer
          ref="managerRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                选择管理员
              </h5>
              <small class="text-muted">请选择审批流的管理员</small>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="管理员"
                rules="required"
              >
                <b-form-group
                  label="管理员"
                  label-for="管理员"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="managers"
                    v-model="managers"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="配置模板"
        :before-change="validateTemplateStep"
      >
        <validation-observer
          ref="templateRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                配置模板
              </h5>
              <small class="text-muted">请配置审批流的模板</small>
              <b-button 
                variant="primary" 
                size="sm"      
                class="ml-1"  
                @click="isControlAddNewSidebarActive = true">
                添加控件
              </b-button>
            </b-col>

            <b-col md="3"></b-col>
            <b-col md="6">

              <p class="text-center" v-if="!controls.length">暂无数据</p>
              <draggable
                v-else
                :list="controls"
                tag="ul"
                group="people"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="control in controls"
                  :key="control.id"
                  tag="li"
                  @click="editControl(control.id)"
                >
                  <div class="d-flex justify-content-between">
                    <div class="ml-25">
                      <b-card-text class="mb-1 font-weight-bold">
                        <span>
                          {{ control.name }}
                          <b-badge variant="light-warning"  class="ml-1 mr-1" v-if="control.isRequired">
                            必填
                          </b-badge>
                           <b-badge variant="light-primary" >
                            {{ mapControlText(control.type) }}
                           </b-badge>
                        </span>
                      </b-card-text>
                      <b-card-text class="mb-1 font-weight-bold" v-if="control.options.length">
                        <span>
                          <small >选项：</small>
                          <small v-for="option in control.options">{{ option }}<small class="text-secondary">；</small></small>
                        </span>
                      </b-card-text>

                      <b-card-text class="mb-1 font-weight-bold" v-if="control.description">
                        <small >控件描述：{{ control.description }}</small>
                      </b-card-text>

                    </div>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon "
                      @click.stop="removeControl(control.id)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </b-list-group-item>
              </draggable>

            </b-col>
            <b-col md="3"></b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="配置流程"
        :before-change="validateWorkflowStep"
      >
        <validation-observer
          ref="workflowRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                配置流程
              </h5>
              <small class="text-muted">请配置审批流的流程</small>
              <b-button
                variant="primary" 
                size="sm"      
                class="ml-1"  
                @click="isWorkflowAddNewSidebarActive = true">
                添加流程
              </b-button>
            </b-col>

            <b-col md="3"></b-col>
            <b-col md="6">

              <p class="text-center" v-if="!workflows.length">暂无数据</p>
              <draggable
                v-else
                :list="workflows"
                tag="ul"
                group="people"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(workflow,index) in workflows"
                  :key="workflow.id"
                  tag="li"
                  @click="editWorkflow(workflow.id)"
                >
                  <div class="d-flex justify-content-between">
                    <div class="ml-25">
                      <b-card-text class="mb-1 font-weight-bold">
                        <span>
                          <b-badge variant="light-primary" class="mr-1" >
                          {{ index + 1 }}
                        </b-badge>
                          {{ workflow.name }}
                          <b-badge 
                            v-if="workflow.mode=='oneof'"
                            variant="light-warning" 
                            class="ml-1 mr-1" >
                            任一人审批
                           </b-badge>

                          <b-badge 
                            v-if="workflow.mode=='all'"
                            variant="light-warning" 
                            class="ml-1 mr-1">
                            全部审批
                          </b-badge>
                        </span>
                      </b-card-text>

                      <b-card-text class="mb-1 font-weight-bold">
                        <small >审批人：</small>
                        <small v-for="member in workflow.members">{{ member.text }}<small class="text-secondary">；</small></small>                      
                      </b-card-text>

                      <b-card-text class="mb-1 font-weight-bold">
                        <small v-if="workflow.description">流程描述：{{ workflow.description }}</small>
                      </b-card-text>
                    </div>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon "
                      @click.stop="removeWorkflow(workflow.id)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </b-list-group-item>
              </draggable>

            </b-col>
            <b-col md="3"></b-col>

          </b-row>
        </validation-observer>
      </tab-content>
     </form-wizard>

    </div>

</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormInvalidFeedback,
  BListGroupItem,
  BButton,
  BFormRadio,
  BCardTitle,
  BBadge,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from '../add-new-view/code'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

import ControlAddNewSidebar from '../add-new-view/ControlAddNewSidebar.vue'
import ControlEditSidebar from '../add-new-view/ControlEditSidebar.vue'

import WorkflowAddNewSidebar from '../add-new-view/WorkflowAddNewSidebar.vue'
import WorkflowEditSidebar from '../add-new-view/WorkflowEditSidebar.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    draggable,
    BListGroupItem,
    BButton,  
    BFormRadio,
    BCardText,
    ControlAddNewSidebar,
    ControlEditSidebar,
    WorkflowAddNewSidebar,
    WorkflowEditSidebar,
    BCard,
    BCardTitle,
    BBadge
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isControlAddNewSidebarActive:false,
      isControlEditSidebarActive:false,

      isWorkflowAddNewSidebarActive:false,
      isWorkflowEditSidebarActive:false,

      editingControl: {},
      editingWorkflow: {},

      userOptions:[],
      controlTypeOptions:[
        {value:"text",text:"文本"},
        {value:"number",text:"整数"},
        {value:"price",text:"金额"},
        //{value:"date",text:"日期"},
        {value:"singleSelect",text:"单选"},
        {value:"multiSelect",text:"多选"},
      ],

      approvalName:'',
      approvalDescription:'',
      managers: [],
      controls: [],
      workflows:[],

    }
  },
  methods: {
    getUsers(){
      this.$axios.get('/manage/users/get_users').then((response) => {
        this.userOptions = []
        response.data.data.users.forEach(user => {
          this.userOptions.push({value:user.user_id,text:user.user_name})
        });
      })
    },
    saveNewApproval(){
      var approvalData = {
        "approvalName":this.approvalName,
        "approvalDescription":this.approvalDescription,
        "managers":[],
        "controls":[],
        "workflows":[],
      }
      this.managers.forEach(manager => {
        approvalData.managers.push(manager.value)
      });
      this.controls.forEach(control => {
        approvalData.controls.push({
          "id":control.id,
          "name":control.name,
          "description":control.description,
          "type":control.type,
          "options":control.options,
          "required":control.isRequired,
        })
      });
      this.workflows.forEach(workflow => {
        approvalData.workflows.push({
          "id":workflow.id,
          "name":workflow.name,
          "mode":workflow.mode,
          "members":[],
          "description":workflow.description,
        })
        workflow.members.forEach(member => {
          approvalData.workflows[approvalData.workflows.length-1].members.push(member.value)
        });
      });
      this.$axios.post('/collab/approvals/add_custom_approval',approvalData).then(resp=>{
        if(resp.data.status == "ok") {
          this.$router.push({name:'collab-approvals'})
      }
      })
    },
    validateBasicInfoStep() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateManagerStep() {
      return new Promise((resolve, reject) => {
        this.$refs.managerRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateTemplateStep() {
      return new Promise((resolve, reject) => {
        if(this.controls.length>0){
          resolve(true)
        }else{
          this.$toast.error('请至少添加一个控件')
          reject()
        }
      })
    },
    validateWorkflowStep() {
      return new Promise((resolve, reject) => {
        if(this.workflows.length>0){
          resolve(true)
        }else{
          this.$toast.error('请至少添加一个流程')
          reject()
        }
      })
    },
    saveNewControl(){
      this.$refs.newControlRules.validate().then(success => {
        if (success) {
          this.$refs.newControlRules.reset()
          this.$refs.newControlModal.hide()
          this.newControlData = {
            name: '',
            description:'',
            type: '',
            options:[],
            isRequired: true,
          }
        }
      })
    },
    removeControl(id) {
      const index = this.controls.findIndex(control => control.id === id)
      this.controls.splice(index, 1)
    },
    editControl(id){
      this.isControlEditSidebarActive = true
      this.editingControl = this.controls.find(control => control.id === id)
    },
    saveEditControl(controlData){
      const index = this.controls.findIndex(control => control.id === controlData.id)
      this.controls.splice(index, 1, controlData)
    },
    editWorkflow(id){
      this.isWorkflowEditSidebarActive = true
      this.editingWorkflow = this.workflows.find(workflow => workflow.id === id)
    },
    saveEditWorkflow(workflowData){
      const index = this.workflows.findIndex(workflow => workflow.id === workflowData.id)
      this.workflows.splice(index, 1, workflowData)
    },
    removeWorkflow(id) {
      const index = this.workflows.findIndex(workflow => workflow.id === id)
      this.workflows.splice(index, 1)
    },
    mapControlText(type){
      switch(type){
        case "text":
          return "文本"
        case "number":
          return "整数"
        case "price":
          return "金额"
        case "date":
          return "日期"
        case "singleSelect":
          return "单选"
        case "multiSelect":
          return "多选"
      }
    },
  },
  created() {
    this.getUsers()
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>


